<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-main>


    <h2>
      Input
    </h2>
    <section class="d-flex pr-2">
      <v-text-field outlined type="number" dense class="me-2" label="Input uno"
        v-model="input.textFieldUno"></v-text-field>

      <v-text-field outlined append-icon="search" dense v-model="input.textFieldDos" label="Input dos" hide-details>
      </v-text-field>

      <v-tooltip left color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small class="ms-2" fab color="success" v-bind="attrs" v-on="on" @click="modalEjemplo = true">
            <v-icon dark> add </v-icon>
          </v-btn>
        </template>
        <span>Agregar</span>
      </v-tooltip>
    </section>


    <!-- Modal para ejemplo -->
    <v-dialog v-model="modalEjemplo" persistent transition="dialog-bottom-transition" max-width="800">
      <v-card>
        <v-card-title class="fondoHeder">
          <span class="text-h6">Modal ejemplo</span>
        </v-card-title>
        <v-card-text>

          <!-- formulario ejemplo -->
          <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form ref="form" class="mt-3" v-model="formValid" lazy-validation>
              <div class="d-flex mt-3">
                <validation-provider style="width: 50%;" v-slot="{ errors }" :rules="{ required: true, }">
                  <v-text-field v-model="form.primerNombre" :error-messages="errors" class="  me-6" label="Primer nombre"
                    dense outlined>
                  </v-text-field>
                </validation-provider>

                <v-text-field v-model="form.segundoNombre" class="" label="Segundo nombre" dense outlined>
                </v-text-field>
              </div>

              <div class="d-flex">
                <validation-provider style="width: 50%" v-slot="{ errors }" :rules="{ required: true }">
                  <v-select v-model="form.tipoDocumento" :items="tiposDoc" label="Tipo de documento"
                    :error-messages="errors" class=" me-6" dense outlined>
                  </v-select>
                </validation-provider>
                <validation-provider style="width: 50%" v-slot="{ errors }" :rules="{ required: true }">
                  <v-text-field v-model="form.numeroDocumento" :counter="11" class="" :error-messages="errors"
                    label="N° de documento" required dense outlined>
                  </v-text-field>
                </validation-provider>
              </div>
              <div class="d-flex">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider style="width: 50%;" v-slot="{ errors }" :rules="{ required: true }">
                      <v-text-field v-model="form.fechaNacimiento" label="Fecha de nacimiento" class=" me-6"
                        :error-messages="errors" readonly v-bind="attrs" v-on="on" dense outlined>
                      </v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker v-model="form.fechaNacimiento" :active-picker.sync="activePicker"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1950-01-01" locale="es-co" :first-day-of-week="1" @change="save"></v-date-picker>
                </v-menu>
                <validation-provider style="width: 50%;" v-slot="{ errors }" rules="required|email">
                  <v-text-field v-model="form.email" :error-messages="errors" label="Email" dense outlined>
                  </v-text-field>
                </validation-provider>
              </div>

              <div class="d-flex justify-space-between">
                <validation-provider style="width: 50%;" v-slot="{ errors }" name="celular" :rules="{
                  required: true,
                  digits: 10,
                  regex: '^(30|31|32|33|34|35|36|37|38|39)\\d{8}$'
                }">
                  <v-text-field v-model="form.numeroCelular" :error-messages="errors" :counter="10" class="me-6"
                    label="Celular" dense outlined>
                  </v-text-field>
                </validation-provider>
                <validation-provider style="width: 50%" v-slot="{ errors }" :rules="{ required: true }">
                  <v-autocomplete v-model="form.regimen" :items="regimen" dense outlined :error-messages="errors"
                    label="Regimen"></v-autocomplete>
                </validation-provider>
              </div>
              <div class="d-flex justify-end">
                <v-btn text color="error" @click="clear()">Cerrar</v-btn>
                <v-btn depressed :disabled="invalid" @click="guardarAfiliado(afiliadoSeleccionado)" color="success">
                  Guardar </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>


    <section>
      <h2>
        Table
      </h2>
      <article>

        <h3>

        </h3>
        <v-data-table :loading="tables.tableUno.loading" fixed-header :headers="tables.tableUno.headers"
          :items="tables.tableUno.body" :items-per-page="tables.tableUno.itemsPerPage" :page.sync="tables.tableUno.page"
          hide-default-footer class="tabla-uno" height="55vh">

        </v-data-table>

        <v-pagination class="paginador-manual" v-model="tables.tableUno.page"
          :length="tables.tableUno.length"></v-pagination>

      </article>

    </section>

  </v-main>
</template>
 
 <!-- #################################################################################### -->
 <!-- ###### Sección de scripts                                                     ###### -->
 <!-- #################################################################################### -->
 
<script>
import { mapMutations, mapState } from "vuex";
import { required, regex, digits, email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';


// validaciones con vee-validate
setInteractionMode('eager')
extend('required', {
  ...required,
  message: 'Este campo es requerido.',
});


extend('digits', {
  ...digits,
  message: 'El {_field_} debe tener {length} números. ({_value_})',
});

extend('regex', {
  ...regex,
  message: 'El {_field_} {_value_} no tiene el formato correcto',
})

extend('email', {
  ...email,
  message: 'Email invalido',
})
// validaciones con vee-validate---fin

export default {
  props: {
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      modalEjemplo: false,
      formValid: null,
      activePicker: null,
      menu: false,
      tiposDoc: ['CC', 'TI', 'RC'],
      regimen: ['RC', 'RS', 'RE', 'NA'],
      input: {
        textFieldUno: '',
        textFieldDos: '',
      },
      form: {
        primerNombre: '',
        segundoNombre: '',
        tipoDocumento: '',
        numeroDocumento: null,
        fechaNacimiento: '',
        email: '',
        numeroCelular: '',
        regimen: '',
      },
      tables: {
        tableUno: {
          cantidadColumnas: 5,
          loading: false,
          headers: this.generarHeaders(5),
          body: this.generarBody(10, 5),
          itemsPerPage: 10,
          page: 1,
          length: 100
        }
      },
      userRoles: {}
    }
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  computed: {
    ...mapState(["auth", "notify", "busy"]),
  },
  methods: {
    ...mapMutations([
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy",
    ]),
    generarHeaders(columnas) {


      return Array(columnas).fill(0).map((element, i) => {
        console.log(element, i)
        return { text: `Columna ${i}`, value: `columna${i}`, sortable: false }
      })
    },
    generarBody(filas, columnas) {

      const elementos = Array(filas).fill(0).map((x, j) => {
        console.log(x, j)
        let retorno = new Object();

        Array(columnas).fill(0).forEach((f, k) => {
          console.log(f)
          let valor = `Columna ${k}`
          Object.defineProperty(retorno, `columna${k}`, {
            get: function () { return valor; },
            set: function (newValue) { valor = newValue; },
            enumerable: true,
            configurable: true
          });
        })

        return retorno;
      })
      return elementos;
    },
    // metodo necesario para el picker de 
    save(date) {
      this.$refs.menu.save(date)
    },

    // metodo para limpiar formulario de ejemplo
    clear() {
      this.form.primerNombre = '';
      this.form.segundoNombre = '';
      this.form.tipoDocumento = '';
      this.form.numeroDocumento = '';
      this.form.fechaNacimiento = '';
      this.form.email = '';
      this.form.numeroCelular = '';
      this.form.regimen = '';
      this.modalEjemplo = false;
    }
  }
}
</script>
 
 
 <!-- #################################################################################### -->
 <!-- ###### Sección de estilos                                                     ###### -->
 <!-- #################################################################################### -->
 
<style scoped>
::v-deep .tabla-uno div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tabla-uno div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

.paginador-manual ul li .v-pagination__item {
  margin: 1px !important;
  min-width: 25px !important;
}

.paginador-manual {
  padding-top: 0%;
}

.fondoHeder {
  background-color: #1867c0;
  color: white;
}
</style>